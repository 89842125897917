.react-tabs {
    -webkit-tap-highlight-color: transparent;
    background-color: #f5f8f9;
  }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid #dfe3ed;
    border-top: 1px solid #dfe3ed;
    margin: 0 0 0px;
    padding: 0;
  }
  
  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    /* padding: 6px 12px; */
    padding:14px;
    cursor: pointer;
    margin:20px 0px 0px 32px;
  }

  
  .react-tabs__tab--selected {
    background: transparent;
    /* border-color: #c50303; */
    border-bottom: 3px solid #c50303;
    color: #c50303;
    border-radius: 5px 5px 0 0;
  }

  
  .react-tabs__tab--disabled {
    color: gray;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    /* background: #fff; */
    background: #fff
  }
  
  .react-tabs__tab-panel {
    display: none;
    background: #ffffff
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }