
input[type=range].ps7 {margin: 10px 0;-webkit-appearance: none;height: 24px;/* width: 100%; */} 
input[type=range].ps7:focus { outline: none; } 
input[type=range].ps7::-webkit-slider-runnable-track { animate: 0.2s;background: #2497E3;border: 0px solid #000000;border-radius: 1px;box-shadow: 0px 0px 0px #000000;cursor: pointer;height: 5px;width: 100%; } 
input[type=range].ps7::-webkit-slider-thumb { background: #A1D0FF;border: 1px solid #2497E3;border-radius: 25px;box-shadow: 0px 0px 0px #000000;cursor: pointer;height: 18px;margin-top: -7px;webkit-appearance: none;width: 18px; } 
input[type=range].ps7:focus::-webkit-slider-runnable-track { background: #2497E3; } 
input[type=range].ps7::-moz-range-track { animate: 0.2s;background: #2497E3;border: 0px solid #000000;border-radius: 1px;box-shadow: 0px 0px 0px #000000;cursor: pointer;height: 5px;width: 100%; } 
input[type=range].ps7::-moz-range-thumb { background: #A1D0FF;border: 1px solid #2497E3;border-radius: 25px;box-shadow: 0px 0px 0px #000000;cursor: pointer;height: 18px;width: 18px; } 
input[type=range].ps7::-ms-track { animate: 0.2s;background: transparent;border-color: transparent;color: transparent;cursor: pointer;height: 5px;width: 100%; } 
input[type=range].ps7::-ms-fill-lower { background: #2497E3;border: 0px solid #000000;border-radius: 2px;box-shadow: 0px 0px 0px #000000; } 
input[type=range].ps7::-ms-fill-upper { background: #2497E3;border: 0px solid #000000;border-radius: 2px;box-shadow: 0px 0px 0px #000000; } 
input[type=range].ps7::-ms-thumb {margin-top:1px; background: #A1D0FF;border: 1px solid #2497E3;border-radius: 25px;box-shadow: 0px 0px 0px #000000;cursor: pointer;height: 18px;width: 18px; } 
input[type=range].ps7:focus::-ms-fill-lower { background: #2497E3; } input[type=range].ps7:focus::-ms-fill-upper { background: #2497E3; }

.slider-crop {
  -webkit-appearance: none;
  width: 50%;
  height: 5px;
  border-radius: 5px;   
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider-crop::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%; 
  background: #4CAF50;
  cursor: pointer;
}

.slider-crop::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}