@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .arrow_box {
    position: relative;
    background: #fcfeff;
    border: 2px solid transparent;
  } */


  /* BarCustom.css*/

  /* .container { 
    display: flex;
    }
  .red {
    background: orangered;
    flex-grow: 1;
  }
  .green {
    background: yellowgreen;
  }
  .blue {
    background: steelblue;
  }
  .container > div {
    font-size: 5vw;
    padding: .5em;
    color: white;
  } */

  /* BarWithScenario.css*/
  /* svg#hsbarExample {
    border: 2px solid #363636;
    border-radius: 8px;
    }
    
    div.header {
    font-size: 24pt;
    background-color: #c6c6c6;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    
    div.footer {
    background-color: #c6c6c6;
    padding: 0px;
    margin: 0px;
    }
    div.footer > h4 {
    margin-bottom: 0px;
    padding-top: 10px;
    }
    div.footer > div {
    display: flex;
    padding: 10px;
    justify-content: center;
    }
    
    div.footer > div > a {
    padding-left: 10px;
    } */
    
    progress {
      vertical-align: baseline;
    }


    @-webkit-keyframes progress-bar-stripes {
      from {
        background-position: 1rem 0;
      }
      to {
        background-position: 0 0;
      }
    }


    @keyframes progress-bar-stripes {
      from {
        background-position: 1rem 0;
      }
      to {
        background-position: 0 0;
      }
    }
    

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
/* add css module styles here (optional) */
._1ceqH,
._1ceqH * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
}

._1ceqH ._2fG9h {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

._1ceqH ._dt3-T {
  min-height: 400px;
  background: #282560;
  border-radius: 0 0 3px 3px;
  display: flex;
  flex-direction: column;
}

._1ceqH ._1dpop {
  width: 60px;
  display: flex;
  height: 60px;
  position: fixed;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  background: #d1354a;
  border-radius: 50%;
  bottom: 65px;
  right: 20%;
  color: #fff;
  font-size: 25px;
}

._1ceqH ._3neb0 {
  width: 25px;
  height: 25px;
  display: flex;
}

._1ceqH ._1dpop:hover {
  background-color: #fff;
}

._1ceqH ._3wi1g {
  fill: #fff;
  width: 100%;
  height: 100%;
}

._1ceqH ._1dpop:hover ._3wi1g {
  fill: #d1354a;
}

/* ._1ceqH ._1lB9c {
  display: flex;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  padding: 20px 20px;
  color: #333;
  border-radius: 3px 3px 0 0;
} */

._1ceqH ._1lB9c ._2N9dq {
  font-weight: 400;
}

/* ._1ceqH ._1lB9c ._3-aC9 {
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s ease all;
} */

._1ceqH ._1lB9c ._3-aC9:hover {
  background: rgba(123, 118, 106, 0.21);
}

._1ceqH ._3bC73 {
  position: relative;
  flex: 1 1;
}

._1ceqH ._3bC73 ._1dpop {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 20px;
}

._1ceqH ._3bC73 ._1YOWG {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  bottom: 100px;
}

._1ceqH ._1Yplu {
  margin: 20px 30px;
}

._1ceqH ._1Yplu ._1Pz2d {
  border: 0;
  outline: 0;
  padding: 10px 20px;
  border-radius: 20px;
  background: #185fec;
  color: #fff;
  cursor: pointer;
  border: 1px solid #185fec;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

._1ceqH ._1Yplu ._1Pz2d:hover {
  background: #fff;
  color: #185fec;
}

._1ceqH ._1Yplu ._2gd2_ {
  background: #fff;
  color: #185fec;
  margin-left: 15px;
}

._1ceqH ._1Yplu ._2gd2_[disabled],
._1ceqH ._1Yplu ._37kfa[disabled] {
  cursor: not-allowed;
  color: #d9d9d9;
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

._1ceqH ._1Yplu ._2gd2_:hover {
  background: #185fec;
  color: #fff;
}

._1ceqH ._f2DT8 {
  text-align: center;
}

._1ceqH ._17RTH {
  min-height: calc(100vh - 128px);
  background: #fff;
}

._1ceqH ._f2DT8 * {
  color: #fff;
  font-size: 60px;
}

._1ceqH ._1YOWG ._eV_dK {
  color: #fff;
}

._1ceqH ._qxztz {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  bottom: 0px;
  padding: 20px 0;
  display: flex;
}

._1ceqH ._qxztz ._2uz65 {
  width: 50px;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  margin-right: 15px;
  font-size: 20px;
}

._1ceqH ._qxztz ._1bSom {
  background: #1e874d;
  position: relative;
}

._1ceqH ._qxztz ._3nQu5 {
  background: #b68812;
  position: relative;
}

._1ceqH ._3O0Io {
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 10px 0px 10px 17px;
  border-color: transparent transparent transparent #fff;
  box-sizing: border-box;
  position: absolute;
  left: 56%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
}

._1ceqH ._2ACrw {
  width: 17px;
  height: 17px;
  border-style: double;
  border-width: 0px 0px 0px 15px;
  border-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

._1ceqH ._oEOY- {
  width: 17px;
  height: 17px;
  border: 1.5px solid #fff;
  border-radius: 2px;
}

._1ceqH ._3neb0 {
  width: 30px;
  height: 30px;
  display: flex;
}

.CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  stroke: gray;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
}

input[type="file"] {
  display: none;
}

/* .ribbon3 {
  z-index: 100;
  width: 65px;
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  position: absolute;
  left: -8px;
  top: 10px;
  background: #753188;
  font-size: 11px;
  border-top-left-radius: 5px;
} */
.ribbon3 {
  width: 30px;
  padding: 10px 0 5px 0;
  position: absolute;
  top: -6px;
  left: 55px;
  text-align: center;
  border-top-left-radius: 5px;
  background: #753188;
  font-size: 11px;
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  z-index: 5;
}
.ribbon3:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: -6px;
  top: 0.1px;
  border-bottom: 6px solid #562f61;
  border-right: 6px solid transparent;
}
.ribbon3:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  bottom: -29.5px;
  left: 0;
}
.ribbon2 {
  width: 30px;
  padding: 10px 0 5px 0;
  position: absolute;
  top: -6px;
  left: 55px;
  text-align: center;
  border-top-left-radius: 5px;
  background: #83829c;
  font-size: 11px;
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  z-index: 5;
}
.ribbon2:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: -6px;
  top: 0.1px;
  border-bottom: 6px solid #282560;
  border-right: 6px solid transparent;
}
.ribbon2:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  bottom: -29.5px;
  left: 0;
}

.row {
  display: flex;
  align-items: stretch;
}

.row .label {
  flex: 0 0 120px;
}

.row .bar-container {
  flex: 1 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.row .bar {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.val-a { background: salmon }
.val-b { background: wheat }
.val-c { background: honeydew }
 
    progress {
        vertical-align: baseline;
      }
  
  
      @-webkit-keyframes progress-bar-stripes {
        from {
          background-position: 1rem 0;
        }
        to {
          background-position: 0 0;
        }
      }
  
  
      @keyframes progress-bar-stripes {
        from {
          background-position: 1rem 0;
        }
        to {
          background-position: 0 0;
        }
      }
      
  
  .progress {
    display: flex;
    height: 0.5rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 1rem;
  }
  
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none;
    }
  }
  
  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none;
    }
  }

input[type=range].ps7 {margin: 10px 0;-webkit-appearance: none;height: 24px;/* width: 100%; */} 
input[type=range].ps7:focus { outline: none; } 
input[type=range].ps7::-webkit-slider-runnable-track { animate: 0.2s;background: #2497E3;border: 0px solid #000000;border-radius: 1px;box-shadow: 0px 0px 0px #000000;cursor: pointer;height: 5px;width: 100%; } 
input[type=range].ps7::-webkit-slider-thumb { background: #A1D0FF;border: 1px solid #2497E3;border-radius: 25px;box-shadow: 0px 0px 0px #000000;cursor: pointer;height: 18px;margin-top: -7px;webkit-appearance: none;width: 18px; } 
input[type=range].ps7:focus::-webkit-slider-runnable-track { background: #2497E3; } 
input[type=range].ps7::-moz-range-track { animate: 0.2s;background: #2497E3;border: 0px solid #000000;border-radius: 1px;box-shadow: 0px 0px 0px #000000;cursor: pointer;height: 5px;width: 100%; } 
input[type=range].ps7::-moz-range-thumb { background: #A1D0FF;border: 1px solid #2497E3;border-radius: 25px;box-shadow: 0px 0px 0px #000000;cursor: pointer;height: 18px;width: 18px; } 
input[type=range].ps7::-ms-track { animate: 0.2s;background: transparent;border-color: transparent;color: transparent;cursor: pointer;height: 5px;width: 100%; } 
input[type=range].ps7::-ms-fill-lower { background: #2497E3;border: 0px solid #000000;border-radius: 2px;box-shadow: 0px 0px 0px #000000; } 
input[type=range].ps7::-ms-fill-upper { background: #2497E3;border: 0px solid #000000;border-radius: 2px;box-shadow: 0px 0px 0px #000000; } 
input[type=range].ps7::-ms-thumb {margin-top:1px; background: #A1D0FF;border: 1px solid #2497E3;border-radius: 25px;box-shadow: 0px 0px 0px #000000;cursor: pointer;height: 18px;width: 18px; } 
input[type=range].ps7:focus::-ms-fill-lower { background: #2497E3; } input[type=range].ps7:focus::-ms-fill-upper { background: #2497E3; }

.slider-crop {
  -webkit-appearance: none;
  width: 50%;
  height: 5px;
  border-radius: 5px;   
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
}

.slider-crop::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%; 
  background: #4CAF50;
  cursor: pointer;
}

.slider-crop::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}
/* .intl-tel-input .flag-container { 

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 24px;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    pointer-events: none;
    border-color: '#c3c3c3';
} */

.intl-tel-input .flag-container { 
    margin-left: -20px;
    margin-top: 7px;
}

.intl-tel-input.allow-dropdown input,.intl-tel-input.allow-dropdown input[type=tel],.intl-tel-input.allowdropdown input[type=text]{
    margin-left:-20px;
    /* padding-left: 63px; */
    border:none;
    /* right:0; */
    position: absolute;
    left:0;
    font-size: 0.9rem;

};



/* svg {
    background-color: gray;
    overflow: visible;
} */
/* body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

body * {
  transition: all 0.300s ease-in-out;
} */

.container {
  background-color: #fff;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
}

/*------------- NAVIGATION BAR -------------------*/

#nav {
  position: fixed;
  z-index: 99;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 12px 0;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr;
  background: #fff;
  box-shadow: 0px 10px 5px -11px rgba(0, 0, 0, 0.5);
}

.nav-container {
  width: 80%;
  display: grid;
  grid-template-columns: 20% 50% 30%;
  margin: 0 auto;
}

nav ul {
  width: 100%;
  display: grid;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

nav ul:first-of-type {
  grid-template-columns: 1fr;
}

nav ul:first-of-type li {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
}

nav ul:first-of-type li span {
  font-weight: 500;
  color: #444;
}

nav ul:last-of-type {
  grid-template-columns: 70% 30%;
}

nav ul:last-of-type li {
  text-align: right;
}

nav ul li a {
  color: #555;
  text-decoration: none;
  font-weight: 600;
  display: block;
  font-size: 14px;
  text-align: center;
}

.main-menu {
  grid-template-columns: repeat(6, 1fr);
}

/*------------- DROPDOWN MENU -------------------*/
.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  cursor: pointer;
  font-size: 14px;
  border: none;
  outline: none;
  color: #555555;
  padding: 0px 10px;
  background-color: inherit;
  /*font: inherit; */
  margin: 0;
}

.dropdown-content {
  margin-top: 0px;
  display: none;
  position: absolute;
  background-color: none;
  width: 100%;
  left: 0;

  z-index: 1;
}

.dropdown-main-content {
  width: 100%;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  text-align: left;
}

.dropdown-content .header {
  background: red;
  padding: 16px;
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/*------------- HEADER -------------------*/

header div.head {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  padding-top: 10%;
  align-items: center;
  text-align: center;
}

header div.head {
  color: #222;
}

header div.head h1 {
  font-size: 5rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 0px;
  margin: 0;
  letter-spacing: 4px;
  border-right: 2px solid #111;
}

header div.head p {
  padding-left: 0px;
}

.contact {
  background: rgb(0, 99, 248);
  display: block;
  color: #fff;
  font-weight: bold;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  padding: 12px;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
}

/*------------- MAIN SECTION -------------------*/
main {
  position: relative;
  z-index: 3;
  overflow: hidden;
}

main section {
  width: 100%;
  padding: 50px 0;
  margin: 0px auto 0px auto;
  display: block;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  text-align: center;
}

main section div {
  width: 80%;
  text-align: center;
  margin: 0 auto;
  /*position: relative; */
}

main section h2 {
  font-size: 1.5rem;
  font-weight: 900;
  color: #222;
  /*
  text-transform: none;
	line-height: 4.2rem; 
	padding-right: 10px;
	border-right: 3px solid #ddd; */
}

main section div p {
  padding-left: 0px;
}

.wrapper-strategy {
  width: 80%;
  display: grid;
  grid-template-columns: 45% 45%;
  grid-gap: 10%;
  /*background-color: #fff; */
  justify-items: center;
  color: #444;
}

.box {
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  padding: 20px;
  font-size: 150%;
}

/* ---------------- SECTION DEMO ---------------------*/
.grid-view-demo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: auto;
}

.demo-title {
  margin: 0px;
  font-weight: 900;
  color: #333333;
}

.text-align-demo-title {
  text-align: left;
}

/* ---------------- SECTION TESTIMONY ---------------------*/
.testimony {
  background-color: #262261;
  color: white;
}

.text-client-title {
  font-weight: 900;
  color: #333333;
}

/* ---------------- SECTION CLIENT & PARTNER ---------------------*/
.box-client {
  display: grid;
  height: auto;
  grid-template-columns: repeat(5, 1fr);
}

/* ---------------- SECTION TOUR ---------------------*/
.box-tour {
  width: 50%;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
}

.box-tour p {
  color: #333333;
}

/* ---------------- SECTION TOUR ---------------------*/
.box-mobile {
  width: 70%;
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1.5fr;
}

.box-mobile-right {
  text-align: left;
}

/* ---------------- SECTION PROMO ---------------------*/
.box-promo {
  width: 70%;
  display: grid;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
}

.box-gray-radius {
  background-color: #e5e5e5;
  border-radius: 10px;
  padding: 10px;
  min-height: 80px;
}

.box-red-gradient {
  background: rgb(244, 105, 124);
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(48%, rgba(244, 105, 124, 1)),
    to(rgba(227, 126, 164, 1))
  );
  background: linear-gradient(
    180deg,
    rgba(244, 105, 124, 1) 48%,
    rgba(227, 126, 164, 1) 100%
  );
  border-radius: 10px;
  padding: 10px;
  min-height: 80px;
  color: #ffffff;
}

/* ---------------- SECTION BOTTOM OF MAIN ---------------------*/
.bottom-main-title {
  font-size: 25px;
  letter-spacing: 2px;
}

/*------------- GALLERY -------------------*/

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 820px;
  width: 95%;
  margin: 180px auto 0px auto;
  box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.9);
}

.gallery div {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  -webkit-filter: grayscale(83%);
          filter: grayscale(83%);
}

/* images are not compressed, remember to compress them before deployment */
.gallery div.gallery-item-one {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
  grid-column-end: span 2;
  grid-row-end: span 2;
}

.gallery div.gallery-item-two {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
}
.gallery div.gallery-item-three {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
}

.gallery div.gallery-item-four {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
}
.gallery div.gallery-item-five {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
}
.gallery div.gallery-item-six {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
}
.gallery div.gallery-item-one:hover,
.gallery div.gallery-item-two:hover,
.gallery div.gallery-item-three:hover,
.gallery div.gallery-item-four:hover,
.gallery div.gallery-item-five:hover,
.gallery div.gallery-item-six:hover {
  background-size: 100%;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

/*------------- SERVICES -------------------*/

div.services {
  display: grid;
  height: auto;
  grid-template-columns: repeat(5, 1fr);
  padding: 0px;
}

.services-div {
  text-align: center;
  background: #fff;
  width: 90%;
  border-radius: 10px;
  margin: 0px auto 20px auto;
  box-shadow: 0px 5px 15px -9px rgba(0, 0, 0, 0.75);
}

.services p.service-icon {
  font-size: 3.4rem;
  padding: 30px 0px 30px 90px;
  margin: 0px 0px 30px 0px;
  text-align: left;
  color: rgb(0, 99, 248);
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  border-bottom: 2px solid rgb(0, 99, 248);
}

.services p.service-title {
  font-size: 3.1rem;
  padding: 0;
  margin: 0;
}

.services p:last-of-type {
  padding: 0px 10px 20px 10px;
  text-align: left;
}

div.recommended {
  display: grid;
  height: auto;
  grid-template-columns: repeat(4, 1fr);
  padding: 0px;
}

.recommended div {
  text-align: center;
  background: #fff;
  width: 80%;
  border-radius: 10px;
  padding: 10px 0px;
  margin: 0px auto 20px auto;
  box-shadow: 0px 5px 15px -9px rgba(0, 0, 0, 0.75);
}

.recommended p.service-icon {
  font-size: 3.4rem;
  padding: 30px 0px 30px 90px;
  margin: 0px 0px 30px 0px;
  text-align: left;
  color: rgb(0, 99, 248);
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  border-bottom: 2px solid rgb(0, 99, 248);
}

.recommended p.service-title {
  font-size: 3.1rem;
  padding: 0;
  margin: 0;
}

.recommended p:last-of-type {
  padding: 0px 10px 20px 10px;
  text-align: left;
}

/* ---------------- PAGE ABOUT ---------------------*/
.box-about {
  margin: 0 auto;
  width: 70%;
}

.box-about-2 {
  display: grid;
  height: auto;
  padding: 0px;
  margin: 0 auto;
  width: 70%;
  grid-template-columns: 46% 8% 46%;
}

/* ---------------- PAGE PRICE ---------------------*/
.box-price {
  display: grid;
  height: auto;
  padding: 0px;
  margin: 0 auto;
  width: 80%;
  grid-template-columns: 75% 25%;
}

.box-price-left-side {
  text-align: left;
}

/* ---------------- PAGE CONTACT ---------------------*/
.box-contact {
  margin: 0 auto;
  width: 70%;
}

.box-contact-address {
  display: grid;
  height: auto;
  padding: 0px;
  margin: 0 auto;
  width: 70%;
  text-align: left;
  grid-template-columns: 47% 6% 47%;
}

/*------------- FORM -------------------*/

main section form {
  padding: 0px;
  margin: 0;
}

main section form div {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: 1fr 3fr;
  align-items: center;
}

main section form div:last-of-type {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

main section form div:last-of-type input {
  background: rgb(0, 99, 248);
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 10px 10px -9px rgba(0, 0, 0, 0.75);
}

main section form div label {
  background: #444;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding: 6px;
  text-align: center;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0px 10px 10px -9px rgba(0, 0, 0, 0.75);
}

main section form div input {
  background: none;
  border: none;
  font-size: 1rem;
}

main section form div textarea {
  font-family: Raleway;
  border: none;
  background: none;
  font-size: 1rem;
  resize: vertical;
}

.error {
  color: rgb(255, 57, 57);
  font-size: 1.3rem;
}

footer {
  /* background: #fff rgb(0, 99, 248); */
  color: #333;
  /* padding: 20px; */
  margin-top: 0px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  /*text-align: center; */
}

.box-footer {
  margin: 0 auto;
  width: 100%;
  display: grid;
  height: auto;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
  padding: 0px;
}

.box-footer-root {
  margin: 0 auto;
  width: 80%;
  padding: 30px;
}

.footer-span-title {
  color: #333;
  font-weight: normal;
}

.footer-icon {
  text-align: left;
}

footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  /*
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; */
  justify-items: left;
  text-align: left;
}

footer ul li a {
  color: #333;
  font-style: normal;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 1.5px;
}

@media (max-width: 1310px) and (min-width: 1100px) {
  header div.head {
    width: 75%;
    padding-top: 190px;
  }

  header div.head h1 {
    font-size: 4rem;
  }

  main section {
    width: 100%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  main section div p {
    padding: 0px 0px;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }

  .services p.service-title {
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type {
    padding: 0px 20px;
    text-align: left;
  }

  .gallery {
    height: 600px;
  }
}

@media (max-width: 1099px) and (min-width: 850px) {
  header {
    /* height: 350px; */
    background-position: bottom;
  }

  header div.head {
    width: 75%;
    padding-top: 400px;
    grid-template-columns: 1fr;
  }

  header div.head h1 {
    font-size: 4rem;
  }

  main section:first-of-type {
    margin-top: 400px;
  }

  main section {
    width: 100%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  main section div p {
    padding: 0px 0px;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }

  .services p.service-title {
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type {
    padding: 0px 20px;
    text-align: left;
  }

  .gallery {
    height: 420px;
  }
}

@media (max-width: 849px) and (min-width: 600px) {
  /* header {
		height: 440px;
	} */

  header div.head {
    width: 75%;
    padding-top: 120px;
    grid-template-columns: 1fr;
  }

  header div.head h1 {
    font-size: 3rem;
  }

  main section {
    width: 100%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  main section div p {
    padding: 0px 0px;
  }

  div.services {
    display: grid;
    grid-template-columns: 1fr;
  }

  div.services div {
    width: 70%;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }

  .services p.service-title {
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type {
    padding: 0px 20px;
    text-align: left;
  }

  .gallery {
    height: 400px;
  }
}

@media (max-width: 599px) and (min-width: 0px) {
  nav {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
  }

  nav ul {
    margin-bottom: 10px;
  }

  nav ul:first-of-type {
    border-bottom: 1px solid #111;
    padding-bottom: 10px;
  }

  nav ul:first-of-type li {
    text-align: center;
    width: 100%;
    padding-top: 0px;
  }

  nav ul:last-of-type li {
    text-align: center;
    width: 100%;
    padding-top: 10px;
  }

  .nav-container {
    /*text-align : center; */
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 auto;
  }

  .main-menu {
    grid-template-columns: repeat(4, 1fr);
  }

  /* HEADER */

  /* header {
		background-size: 160%;
		position: relative;  
	} */

  header div.head {
    width: 75%;
    grid-template-columns: 1fr;
    padding-top: 160px;
  }

  header div.head h1 {
    font-size: 2.3rem;
    border: none;
  }

  /* header div.head p {
		padding-left: 0px;
	} */

  main section:first-of-type {
    margin-top: 250px;
  }

  main section {
    width: 100%;
    padding: 10px 0;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  main section div p {
    padding: 0px 0px;
    text-align: justify;
  }

  /* STRATEGY*/
  .wrapper-strategy {
    width: 90%;
    display: grid;
    grid-template-columns: 90%;
    grid-gap: 20px;
    /*background-color: #fff; */
    justify-items: center;
    color: #444;
  }

  /*------------- SERVICES -------------------*/
  div.services {
    display: grid;
    grid-template-columns: 1fr;
  }

  div.services div {
    width: 100%;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }

  .services p.service-title {
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type {
    padding: 0px 20px;
    text-align: left;
  }

  /*------------- RECOMMENDED -------------------*/
  div.recommended {
    display: grid;
    grid-template-columns: 1fr;
  }

  div.recommended div {
    width: 95%;
  }

  /* ---------------- SECTION DEMO ---------------------*/
  .grid-view-demo {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
  }

  /*------------- GALLERY -------------------*/
  .gallery {
    grid-template-columns: 1fr;
    height: auto;
    margin: 30px auto 0px auto;
  }

  .gallery div {
    height: 200px;
  }

  .gallery div.gallery-item-one {
    grid-column-end: span 1;
    grid-row-end: span 1;
  }

  main section form div label {
    margin-right: 10px;
    border-radius: 5px;
  }

  main section form div:last-of-type input {
    padding: 10px;
    border-radius: 5px;
  }

  footer {
    padding: 20px 0;
    text-align: center;
    grid-template-columns: 1fr;
    font-size: 12px;
  }

  footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
    /*
	  display: grid;
	  grid-template-columns: 1fr 1fr 1fr; */
    justify-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  footer ul li a {
    font-size: 12px;
  }

  .box-footer {
    margin: 0 auto;
    width: 100%;
    display: grid;
    height: auto;
    grid-template-columns: 1fr;
    padding: 0px;
  }

  .box-footer-root {
    margin: 0 auto;
    width: 90%;
  }

  .footer-span-title {
    color: #333;
    font-weight: normal;
  }

  .footer-icon {
    text-align: center;
  }
}

.reactOrgChart {
    margin: 2px;
}

.reactOrgChart .orgNodeChildGroup .node {
    border: solid 1px #000000;
    display: inline-block;
    padding: 2px;
    width: 100px;
}

.reactOrgChart .orgNodeChildGroup .nodeCell {
    text-align: center;
}

.reactOrgChart .orgNodeChildGroup .nodeGroupCell {
    vertical-align: top;
}

.reactOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
    height: 25px;
    width: 50%;
    border-right: 2px solid #000;
}

.reactOrgChart .nodeLineBorderTop {
    border-top: solid 2px #000;
}

.reactOrgChart table {
    border-collapse: collapse;
    border: none;
    margin: 0 auto;
}

.reactOrgChart td {
    padding: 0;
}

.reactOrgChart table.nodeLineTable {
    width: 100%;
}

.reactOrgChart table td.nodeCell {
    width: 50%;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
    background-color: #f5f8f9;
  }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid #dfe3ed;
    border-top: 1px solid #dfe3ed;
    margin: 0 0 0px;
    padding: 0;
  }
  
  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    /* padding: 6px 12px; */
    padding:14px;
    cursor: pointer;
    margin:20px 0px 0px 32px;
  }

  
  .react-tabs__tab--selected {
    background: transparent;
    /* border-color: #c50303; */
    border-bottom: 3px solid #c50303;
    color: #c50303;
    border-radius: 5px 5px 0 0;
  }

  
  .react-tabs__tab--disabled {
    color: gray;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    /* background: #fff; */
    background: #fff
  }
  
  .react-tabs__tab-panel {
    display: none;
    background: #ffffff
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
.task-list-table_taskListWrapper__bP7My {
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
}

.task-list-table_taskListTableRow__pBdt8 {
  display: table-row;
  text-overflow: ellipsis;
}

.task-list-table_taskListTableRow__pBdt8:nth-of-type(even) {
  background-color: #f5f5f5;
}

.task-list-table_taskListCell__3mLrV {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-list-table_taskListNameWrapper__1qnYE {
  display: flex;
}

.task-list-table_taskListExpander__2vM6U {
  color: rgb(86 86 86);
  font-size: 0.6rem;
  padding: 0.15rem 0.2rem 0rem 0.2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.task-list-table_taskListEmptyExpander__1Djzo {
  font-size: 0.6rem;
  padding-left: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.task-list-header_ganttTable__1xjMh {
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-top: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
}

.task-list-header_ganttTable_Header__2-T6V {
  display: table-row;
  list-style: none;
}

.task-list-header_ganttTable_HeaderSeparator__2CtyK {
  border-right: 1px solid rgb(196, 196, 196);
  opacity: 1;
  margin-left: -2px;
}

.task-list-header_ganttTable_HeaderItem__H3dEP {
  display: table-cell;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0 0.5rem;
  display: flex;
  /* min-height: calc(100vh - 5rem); */
  overflow-y: auto;
}

.input-card {
  background-color: #ebecf0;
  padding: 1rem;
  border-radius: 10px;
}
.input-card .input-card-container {
  width: 100%;
  margin: auto;
}
.input-card .input-card-container .input-text {
  width: 100%;
  height: 4rem;
  background-color: #fff;
  padding: 0.5rem;
  font-size: 15px;
  border: none;
  border-bottom: 1px solid #ccc;
  resize: none;
}
.input-card .confirm {
  width: 268px;
  margin: 0.2rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-card .confirm .button-confirm {
  height: 2rem;
  width: 6rem;
  background-color: #525252;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  margin-right: 0.5rem;
  text-transform: capitalize;
}
.input-card .confirm .button-confirm:hover {
  background-color: #e22bba;
}
.input-card .confirm .button-cancel {
  background-color: none;
  border: none;
  cursor: pointer;
}
.input-card .confirm .button-cancel svg {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.input-card .confirm .button-cancel svg:hover {
  color: #e22bba;
}

.input-container {
  width: 100%;
  max-width: 300px;
  margin-right: 0.5rem;
}
.input-container .input-content {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 0.5rem 0;
  opacity: 0.8;
}
.input-container .input-content button {
  cursor: pointer;
  background: none;
  width: 100%;
  padding: 0.5rem;
  border: none;
  margin: auto;
  text-align: left;
  font-size: 14px;
}
.input-container .input-content:hover {
  background-color: #ddd;
}

.input-title {
  width: 100%;
  height: 1rem;
  font-size: 0.7rem;
  border: none;
}
.input-title:focus {
  background: #ddd;
}
.editable-title-container {
  position: relative;
  display: flex;
}
.editable-title-container .editable-title {
  height: 1rem;
  flex-grow: 1;
  font-size: 0.5rem;
  /* font-weight: bold; */
}
.editable-title-container .list-button {
  height: 1.5rem;
  border: none;
  cursor: pointer;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.editable-title-container .list-button:hover {
  background-color: #ddd;
}
.editable-title-container .menu-card {
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 999;
  list-style-type: none;
  cursor: default;
}
.editable-title-container .menu-card li {
  color: #333;
  font-size: 0.875rem;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 0.5rem 0.3rem;
}
.editable-title-container .menu-card li:hover {
  background-color: #ddd;
}

.list-cards {
  width: 300px;
  background-color: #f2f2f2;
  margin-right: 0.5rem;
  border-radius: 10px;
}
.list-cards .title-list {
  padding: 0.7rem 0.7rem;
}
.list-cards .container-cards {
  overflow-x: auto;
  max-height: 55vh;
  padding: 0 0.7rem;
}
.list-cards .card-container {
  overflow-y: hidden;
  margin: 0.5rem 0;
}

#container {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  /* height: 400px; */
  background-color: #fff;
}

.comparison {
  background-color: "#888";
}

.header {
  background-color: white;
}

.comparison {
  background-color: "#888";
}

.header {
  background-color: white;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

