/* .intl-tel-input .flag-container { 

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 24px;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    pointer-events: none;
    border-color: '#c3c3c3';
} */

.intl-tel-input .flag-container { 
    margin-left: -20px;
    margin-top: 7px;
}

.intl-tel-input.allow-dropdown input,.intl-tel-input.allow-dropdown input[type=tel],.intl-tel-input.allowdropdown input[type=text]{
    margin-left:-20px;
    /* padding-left: 63px; */
    border:none;
    /* right:0; */
    position: absolute;
    left:0;
    font-size: 0.9rem;

};


